.manage-entries {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.manage-entries-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.manage-entries-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a472a;
  margin: 0;
}

.manage-entries-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.golf-pools-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.manage-entries-table-container {
  width: 100%;
  overflow: auto;
  border-radius: 8px;
  max-height: 70vh;
  position: relative;
}

.manage-entries-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  font-size: 0.95rem;
}

.manage-entries-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.manage-entries-table th {
  background-color: #1a472a;
  color: white;
  font-weight: 600;
  text-align: left;
  padding: 0.875rem 1rem;
  white-space: nowrap;
}

.manage-entries-table td {
  padding: 0.875rem 1rem;
  border-bottom: 1px solid #e2e8f0;
  white-space: nowrap;
}

.manage-entries-table tr:hover {
  background-color: #f7fafc;
}

.manage-entries-checkbox {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #1a472a;
  border-radius: 4px;
  cursor: pointer;
  accent-color: #1a472a;
}

.manage-entries-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  background: none;
  border: none;
  color: #4a5568;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 50%;
  margin: 0 auto;
}

.manage-entries-button:hover:not(:disabled) {
  background-color: rgba(26, 71, 42, 0.1);
  color: #1a472a;
}

.manage-entries-button:disabled {
  color: #a0aec0;
  cursor: not-allowed;
}

.resend-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Custom scrollbar styles for vertical scrolling */
.manage-entries-table-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.manage-entries-table-container::-webkit-scrollbar-track {
  background: #f8f9fa;
  border-radius: 6px;
}

.manage-entries-table-container::-webkit-scrollbar-thumb {
  background: rgba(26, 71, 42, 0.6);
  border-radius: 6px;
  border: 2px solid #f8f9fa;
  transition: background-color 0.2s ease;
}

.manage-entries-table-container::-webkit-scrollbar-thumb:hover {
  background: rgba(26, 71, 42, 0.8);
}

.manage-entries-table-container::-webkit-scrollbar-corner {
  background: #f8f9fa;
}

/* Firefox scrollbar styles */
.manage-entries-table-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(26, 71, 42, 0.6) #f8f9fa;
}

.manage-entries-tr {
  transition: background-color 0.2s ease;
}

.manage-entries-tr:hover {
  background-color: #f8fafc;
}

.manage-entries-td {
  vertical-align: middle;
}

.manage-entries-td:last-child {
  text-align: center;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.no-entries-message {
  text-align: center;
  padding: 2rem;
  color: #4a5568;
  font-style: italic;
}

.pool-modal-spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@media (max-width: 768px) {
  .manage-entries-header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .manage-entries-table {
    font-size: 0.875rem;
  }

  .manage-entries-table th,
  .manage-entries-table td {
    padding: 0.75rem;
  }
} 