.pool-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
  padding: 1rem;
}

.pool-modal-content {
  background: #eef1f4;
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  width: 100%;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.pool-modal-close-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
}

.pool-modal-close-button {
  background: none;
  border: none;
  color: #1a472a;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 50%;
}

.pool-modal-close-button:hover {
  color: #e53e3e;
}

.pool-modal-inner-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
}

.pool-modal-body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
}

.pool-modal-tabs {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  margin: 2.5rem 0 1.25rem 0;
  border-bottom: none;
  width: 100%;
}

.pool-modal-tab {
  flex: 1;
  max-width: 200px;
  text-align: center;
  padding: 0.875rem;
  background: #eef1f4;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  color: #1a472a;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.pool-modal-tab:hover {
  background-color: rgba(26, 71, 42, 0.1);
  color: #1a472a;
}

.pool-modal-tab.active {
  background-color: #1a472a;
  color: white;
  box-shadow: 0 4px 12px rgba(26, 71, 42, 0.15);
}

.pool-modal-tab:disabled {
  background-color: #e2e8f0;
  color: #a0aec0;
  cursor: not-allowed;
  opacity: 0.7;
}

.pool-modal-tab:disabled:hover {
  background-color: #e2e8f0;
  transform: none;
  box-shadow: none;
}

.pool-modal-nav-bar {
  position: sticky;
  bottom: 0;
  background: #eef1f4;
  padding: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  z-index: 1;
}

/* Form Styles */
/* .pool-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
} */

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 1.5rem;
}

.form-label {
  font-size: 0.95rem;
  font-weight: 600;
  color: #1a472a;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  text-align: left;
  margin-bottom: 0.25rem;
}

.form-input,
.form-select {
  width: 100%;
  padding: 0.75rem 1rem;
  height: 2.75rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  color: #2d3748;
  background-color: #ffffff;
  font-weight: 500;
  transition: all 0.3s ease;
}

.form-input:focus,
.form-select:focus {
  outline: none;
  border-color: #1a472a;
  background-color: #ffffff;
  box-shadow: 0 0 0 3px rgba(26, 71, 42, 0.1);
}

.form-input.error,
.form-select.error {
  border-color: #e53e3e;
}

.form-checkbox-group {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  padding: 0.5rem 0;
  /* margin-bottom: 3rem; */
  width: 100%;
}

.form-checkbox {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #1a472a;
  border-radius: 4px;
  cursor: pointer;
  accent-color: #1a472a;
}

.form-checkbox-label {
  font-size: 0.95rem;
  color: #2d3748;
  cursor: pointer;
  font-weight: 500;
}

.error-message {
  color: #e53e3e;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Invite Form Styles */
.invite-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  margin-bottom: 3rem; /* Add space for buttons */
}

.invite-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem; /* Reduced from 1rem */
}

.email-input-row {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  margin-bottom: 0.25rem; /* Reduced from 0.5rem */
}

.email-input {
  flex: 1;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  color: #2d3748;
  background-color: white;
  transition: all 0.2s ease;
}

.email-input:focus {
  outline: none;
  border-color: #1a472a;
  box-shadow: 0 0 0 3px rgba(26, 71, 42, 0.1);
}

.email-remove-button {
  background: none;
  border: none;
  color: #e53e3e;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 50%;
}

.email-remove-button:hover {
  background-color: rgba(229, 62, 62, 0.1);
}

/* Button Styles */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.form-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.875rem 1.25rem;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.form-button.primary {
  background-color: #1a472a;
  color: white;
  box-shadow: 0 4px 12px rgba(26, 71, 42, 0.15);
}

.form-button.primary:hover {
  background-color: #2c6a43;
  transform: translateY(-1px);
  box-shadow: 0 6px 15px rgba(26, 71, 42, 0.2);
}

.form-button.secondary {
  background-color: #e2e8f0;
  color: #4a5568;
}

.form-button.secondary:hover {
  background-color: #cbd5e0;
}

.form-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.disabled-message {
  font-size: 0.875rem;
  color: #718096;
  font-style: italic;
  margin-top: 0.5rem;
}

.nav-bar-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.modal-nav-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.875rem 1.5rem;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
  background-color: #1a472a;
  color: white;
  box-shadow: 0 4px 12px rgba(26, 71, 42, 0.15);
}

.modal-nav-button:hover {
  background-color: #2c6a43;
  transform: translateY(-1px);
  box-shadow: 0 6px 15px rgba(26, 71, 42, 0.2);
}

.modal-nav-button:disabled {
  background-color: #a0aec0;
  color: #ffffff;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.modal-nav-button:disabled:hover {
  background-color: #a0aec0;
  transform: none;
  box-shadow: none;
}

.invite-buttons-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.add-email-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #e2e8f0;
  color: #4a5568;
  border: none;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.add-email-button:hover {
  background-color: #cbd5e0;
}

.submit-invite-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #1a472a;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.submit-invite-button:hover {
  background-color: #2c6a43;
  transform: translateY(-1px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.submit-invite-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive styles */
@media (max-width: 600px) {
  .pool-modal-content {
    margin-top: 2rem;
    max-height: 85vh;
  }

  .pool-modal-inner-container {
    padding: 1.5rem 1rem;
  }

  .pool-modal-body {
    margin-bottom: 0.5rem;
  }

  .pool-modal-nav-bar {
    padding: 0.75rem;
  }

  .form-input,
  .form-select {
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
    height: 2.5rem;
  }

  .form-label {
    font-size: 0.9rem;
  }

  .form-checkbox-label {
    font-size: 0.9rem;
  }

  .pool-modal-tab {
    padding: 0.625rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .pool-modal-inner-container {
    padding: 1rem;
  }

  .form-input,
  .form-select {
    font-size: 0.875rem;
    padding: 0.5rem;
    height: 2.25rem;
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  z-index: 1002;
  backdrop-filter: blur(4px);
}

.spinner {
  transform: translateY(-25%);
}

.pool-modal-spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
} 