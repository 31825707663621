.dashboard-container {
  max-width: 1400px;
  margin: 5rem auto 2rem;
  padding: 0 1rem;
  width: 95%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.welcome-card {
  background-color: #e2e8f0;
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.dashboard-header h1 {
  color: #1a472a;
  font-size: 1.8rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.create-pool-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #1a472a;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  min-width: fit-content;
  flex-shrink: 0;
}

.create-pool-button:hover {
  background-color: #2c6d42;
  transform: translateY(-1px);
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;
}

/* Center last item if it's alone in its row (desktop only) */
.dashboard-grid > *:last-child:nth-child(2n - 1) {
  grid-column: 1 / -1;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
}

.dashboard-section,
.welcome-card {
  background-color: #e2e8f0;
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.section-header h2 {
  color: #1a472a;
  font-size: 1.2rem;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.view-all {
  color: #1a472a;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.view-all:hover {
  opacity: 0.8;
}

.dashboard-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.dashboard-item {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  text-decoration: none;
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.dashboard-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

.item-header h3 {
  color: #1a472a;
  margin: 0;
  font-size: 1rem;
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-header .item-status {
  flex-shrink: 0;
  white-space: nowrap;
}

.item-details {
  display: flex;
  justify-content: space-between;
  color: #4a5568;
  font-size: 0.9rem;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  flex: 1;
}

.loading {
  text-align: center;
  color: #4a5568;
  padding: 2rem;
}

.empty-state {
  text-align: center;
  color: #4a5568;
  padding: 2rem;
  font-style: italic;
}

/* Single Mobile Breakpoint */
@media screen and (max-width: 550px) {
  .dashboard-container {
    margin: 1rem 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .dashboard-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 0;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }

  /* Override desktop last-child styling on mobile */
  .dashboard-grid > *:last-child:nth-child(2n - 1) {
    grid-column: auto;
    width: 100%;
    margin: 0;
  }

  .dashboard-section,
  .welcome-card {
    width: 100%;
    margin: 0 0 1rem 0;
    padding: 1rem;
    border-radius: 12px;
    box-sizing: border-box;
  }

  .welcome-card {
    margin: 0 16px 1rem 16px;
    width: calc(100% - 32px);
    padding-right: calc(1rem + 8px);
  }

  .dashboard-section {
    padding-right: calc(1rem + 8px);
  }

  .dashboard-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding-right: 8px;
  }

  .dashboard-header h1 {
    font-size: 1.2rem;
    flex: 1;
    min-width: 0;
  }

  .create-pool-button {
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
    white-space: nowrap;
  }

  .dashboard-list {
    gap: 0.75rem;
  }

  .dashboard-item {
    padding: 0.75rem;
    width: 100%;
    box-sizing: border-box;
  }

  .item-header {
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .item-header h3 {
    font-size: 0.95rem;
    max-width: calc(100% - 80px);
  }

  .item-details {
    font-size: 0.85rem;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .section-header {
    margin-bottom: 1rem;
  }

  .section-header h2 {
    font-size: 1.1rem;
  }

  .truncate-text {
    max-width: 100%;
  }
}

/* Status Badges */
.item-status {
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
}

.status-not-started {
  background-color: #f3f4f6;
  color: #6b7280;
}

.status-in-progress {
  background-color: #dcfce7;
  color: #166534;
}

.status-completed {
  background-color: #dbeafe;
  color: #1e40af;
}

.status-error {
  background-color: #fee2e2;
  color: #991b1b;
}

@media screen and (max-width: 768px) {
  .item-header {
    gap: 0.75rem;
  }
  
  .item-header h3 {
    font-size: 0.9rem;
  }
} 